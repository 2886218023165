<template>
  <div class="container" style="margin-top:10px">
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    />
    <div class="box">
      <div class="is-size-3">
        Edital de Notificação
      </div>
      <div class="column is-centered">
        <div class="columns is-centered">
          <b-field label="Tipo">
            <b-select
              v-model="filtroSelecionado"
              expanded
              style="max-width: 400px"
              @input="getListaPostagem"
            >
              <option value="notautuacao">
                Notificação de Autuação
              </option>
              <option value="notpenalidade">
                Notificação de Penalidade
              </option>
              <option value="nic">
                Nic
              </option>
            </b-select>
          </b-field>
        </div>
        <div>
          <lista-edital
            :lista-datas="listaData"
            :tipo-edital="filtroSelecionado"
            @change-month="changeMonth"
            @change-year="changeYear"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ListaEdital from '../components/editais/lista-edital.vue'
export default {
  components: {
    ListaEdital
  },
  layout: process.env.VUE_APP_LAYOUT_DEFAULT,
  data () {
    return {
      filtroSelecionado: 'notautuacao',
      activetab: 0,
      isLoading: false,
      year: new Date().getFullYear(),
      month: new Date().getMonth()
    }
  },
  computed: {
    ...mapState({
      notificacaoAutuacao: state => state.editais.notificacaoAutuacao,
      notificacaoPenalidade: state => state.editais.notificacaoPenalidade,
      nic: state => state.editais.nic
    }),
    listaData () {
      switch (this.filtroSelecionado) {
        case 'notautuacao':
          return this.notificacaoAutuacao
        case 'notpenalidade':
          return this.notificacaoPenalidade
        case 'nic':
          return this.nic
        default:
          return []
      }
    }
  },
  mounted () {
    this.getListaPostagem()
  },
  methods: {
    ...mapActions('editais', [
      'getNotificacaoAutuacao',
      'getNotificacaoPenalidade',
      'getNIC'
    ]),
    changeMonth (month) {
      this.month = month + 1
      this.getListaPostagem()
    },
    changeYear (year) {
      this.year = year
      this.getListaPostagem()
    },
    getListaPostagem () {
      const dtPostagem = `${this.year}-${this.month}-01`
      let lista
      this.isLoading = true
      switch (this.filtroSelecionado) {
        case 'notautuacao':
          lista = this.getNotificacaoAutuacao(dtPostagem)
          break
        case 'notpenalidade':
          lista = this.getNotificacaoPenalidade(dtPostagem)
          break
        case 'nic':
          lista = this.getNIC(dtPostagem)
          break
      }
      lista
        .then(() => {
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.$buefy.toast.open({
            message:
              'Não foi possivel carregar lista de editais, tente novamente mais tarde.',
            position: 'is-bottom',
            type: 'is-danger'
          })
        })
    }
  }
}
</script>
