<template>
  <div>
    <div
      class="columns is-centered"
      style="margin-top:10px"
    >
      <div>
        <b-datepicker
          v-model="date"
          :month-names="monthNames"
          :max-date="maxDate"
          :min-date="minDate"
          :selectable-dates="listaDatas"
          inline
          @change-month="changeMonth"
          @change-year="changeYear"
        />
        <div
          class="columns is-centered"
          style="margin-top:10px"
        >
          <a
            :href="editalLink"
            :disabled="date == null || listaDatas.length === 0"
            class="button is-primary"
            target="_blank"
          >
            Gerar Edital
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns'
export default {
  props: {
    listaDatas: {
      type: Array,
      required: true
    },
    tipoEdital: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      date: null,
      monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      maxDate: new Date(),
      isLoading: false,
      year: new Date().getFullYear(),
      month: new Date().getMonth()
    }
  },
  computed: {
    editalLink () {
      if (this.date == null || this.listaDatas.length === 0) { return }

      let tipoEdital
      switch (this.tipoEdital) {
        case 'notautuacao':
          tipoEdital = 'NotificacaoDeAutuacao'
          break
        case 'notpenalidade':
          tipoEdital = 'NotificacaoDePenalidade'
          break
        case 'nic':
          tipoEdital = 'Nic'
          break
        default:
          tipoEdital = ''
          break
      }
      const dataEdital = format(new Date(this.date), 'yyyy-MM-dd')
      return `${process.env.VUE_APP_BASE_URL}/edital/${process.env.VUE_APP_CLIENTID}/${tipoEdital}/${dataEdital}`
    },
    minDate () {
      const curDate = new Date()
      return new Date(`${curDate.getFullYear() - 1}-01-01 00:00:00`)
    }
  },
  methods: {
    changeMonth (month) {
      this.month = month
      this.$emit('change-month', this.month)
    },
    changeYear (year) {
      this.year = year
      this.$emit('change-year', this.year)
    }
  }
}
</script>

<style>
</style>
